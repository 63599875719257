import { Component} from '@angular/core';


@Component({
  selector: 'unauthorized-component',
  templateUrl: './unauthorized.component.html'
})
export class Unauthorized{
  constructor() {}
}
