// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: '/api/',
  system: "QA",

  ///DEV
  //redirectionURL: "https://epd.fremont.lamrc.net/",
  //linksUrl: 'http://epd.fremont.lamrc.net/',
  //forecast3: 'https://sfsforecastdev.lamrc.net',
  //forecast4: 'https://sfsforecastdev.lamrc.net',
  //nonConformances: 'https://nonconformancesdev.lamrc.net',
  //lamMessage: "https://mylamhome-dev.lamrc.net/home",
  //firstArticle: 'https://firstarticle-dev.lamrc.net'

  ///QA
  redirectionURL: "https://qa-portal.mylam.com/",
  linksUrl: 'https://qa-portal.mylam.com/',
  forecast3: 'https://sfsforecastqa.mylam.com',
  forecast4: 'https://sfsforecastqa.mylam.com',
  nonConformances: 'https://nonconformancesqa.mylam.com',
  lamMessage: "https://mylamhome-qa.mylam.com/home",
  firstArticle: 'https://firstarticle-qa.mylam.com'

  ///PROD
  //redirectionURL: "https://portal.mylam.com/",
  //linksUrl: 'https://portal.mylam.com/',
  //forecast3: 'https://sfsforecast.mylam.com',
  //forecast4: 'https://sfsforecast.mylam.com',
  //nonConformances: 'https://nonconformances.mylam.com',
  //lamMessage: "https://mylamhome.mylam.com/home",
  //firstArticle: 'https://firstarticle.mylam.com'
};
