import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  //tokenValue: any;

  constructor(private authService: AuthService) {
    //this.tokenValue = ;
    //console.log("intercepted initiated ... ");
  }
  
  getTokenValue(): string {    
    return this.authService.getAuthorizationToken();   
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //console.log("intercepted request ... ", req);

    // Clone the request to add the new header.
    //const authReq = req.clone({ headers: req.headers.set("headerName", "headerValue") });

    //token = this.auth.getToken() // auth is provided via constructor.
    //if (token) {
    //  req.setHeader('Authorization', 'Bearer ' + token)
    //}

    if (req.url.indexOf('/Auth') != -1) {
      //console.log("call to auth ...");
      return next.handle(req); // do nothing
    }
 
    req = req.clone({      
      setHeaders: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.getTokenValue(),
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      }
    });

    //if (req.url.indexOf('/UpdateData') != -1) {
    //  console.log("call clone for update ...");
    //  req = req.clone({
    //    setHeaders: {
    //      'Content-Type': 'application/x-www-form-urlencoded',
    //      'content': 'application/json',
    //      'Accept': 'application/json',
    //      'Authorization': 'Bearer ' + this.getTokenValue(),
    //      'Cache-Control': 'no-cache',
    //      'Pragma': 'no-cache',
    //      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
    //    }
    //  });
    //}
    
    //console.log("Sending request with new header now ...   ", req);
    return next.handle(req);

    //return next.handle(authReq).pipe(

    //);
    //send the newly created request
    //return next.handle(authReq)
    //  .catchError((error, caught) => {
    //    //intercept the respons error and displace it to the console
    //    console.log("Error Occurred");
    //    console.log(error);
    //    //return the error to the method that called it
    //    return Observable.throwError(error);
    //  }) as any;
  }
}
