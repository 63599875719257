import { Injectable } from '@angular/core';

/** Mock client-side authentication/authorization service */
@Injectable()
export class AuthService {
  private authToken: any = 'some-auth-token custom token'

  getAuthorizationToken() {
    return this.authToken;
  }

  setAuthorizationToken(val:any) {
    return this.authToken = val;
  }
}
