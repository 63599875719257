import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { DataServices } from './services/data.services';
import { AuthService } from './services/auth.service';
import { CustomHttpInterceptor } from './services/CustomHttpInterceptor';

import { JwtHelper } from './shared/jwt-helper-service';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Unauthorized } from './unauthorized/unauthorized.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    Unauthorized
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'unauthorized', pathMatch: 'full' },
      { path: 'leadtime', component: HomeComponent, pathMatch: 'full' },
      { path: 'unauthorized', component: Unauthorized}
    ]),
    DropDownsModule,
    BrowserAnimationsModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: CustomHttpInterceptor,
    multi: true
  },
    JwtHelper,
    DataServices, AuthService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
