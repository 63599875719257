
export class FilterModel {
  public supplierCode: string = "";
  public partNo: string = "";
  public buyer: string = "";
  public sourceCode: string = "";
  public sbm: string = "";
  public leadTime: string = "";
  public targetLT: string = "";
  public demand: string = "";
  public action: string = "";
  public plant: string = "";
  public pageSize: number = 100;
  public pageNumber: string = "1";
  public isDownload: string = "no";
}
